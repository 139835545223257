import { ActionType, createAction, props } from "@ngrx/store";
import {
  GetLoanCountAndAssignedToResult,
  InitiateLoanDetails,
  Loan,
  LoanFilterData,
  LoanStatusUpdate,
} from "./loan.types";

export const GET_LOANS = "GET_LOANS";
export const GET_LOANS_SUCCESS = "GET_LOANS_SUCCESS";
export const GET_LOANS_FAILURE = "GET_LOANS_FAILURE";

export const GetLoans = createAction(GET_LOANS, props<{ data: any }>());
export const GetLoansSuccess = createAction(GET_LOANS_SUCCESS, props<{ data: Loan[] }>());
export const GetLoansFailure = createAction(GET_LOANS_FAILURE, props<{ errors?: string[]; message: string }>());

export const INITIATE_LOAN = "INITIATE_LOAN";
export const INITIATE_LOAN_SUCCESS = "INITIATE_LOAN_SUCCESS";
export const INITIATE_LOAN_FAILURE = "INITIATE_LOAN_FAILURE";
export const CLEAR_INITIATE_LOAN_FAILURE = "CLEAR_INITIATE_LOAN_FAILURE";
export const DELETE_BANK_ACCOUNT_BY_ID = "DELETE_BANK_ACCOUNT_BY_ID";
export const DELETE_BANK_ACCOUNT_BY_ID_SUCCESS = "DELETE_BANK_ACCOUNT_BY_ID_SUCCESS";
export const DELETE_BANK_ACCOUNT_BY_ID_FAILURE = "DELETE_BANK_ACCOUNT_BY_ID_FAILURE";

export const GET_LOAN_BY_ID = "GET_LOAN_BY_ID";
export const GET_LOAN_BY_ID_SUCCESS = "GET_LOAN_BY_ID_SUCCESS";
export const GET_LOAN_BY_ID_FAILURE = "GET_LOAN_BY_ID_FAILURE";

export const InitiateLoan = createAction(INITIATE_LOAN, props<{ loanData: InitiateLoanDetails }>());
export const InitiateLoanSuccess = createAction(INITIATE_LOAN_SUCCESS, props<{ loanData: InitiateLoanDetails }>());
export const InitiateLoanFailure = createAction(INITIATE_LOAN_FAILURE, props<{ errors?: string[]; message: string }>());
export const ClearInitiateLoanFailure = createAction(CLEAR_INITIATE_LOAN_FAILURE);
export const DeleteBankAccountById = createAction(DELETE_BANK_ACCOUNT_BY_ID, props<{ bankAccountId: string }>());
export const DeleteBankAccountByIdSuccess = createAction(DELETE_BANK_ACCOUNT_BY_ID_SUCCESS, props<{ data: boolean }>());
export const DeleteBankAccountByIdFailure = createAction(
  DELETE_BANK_ACCOUNT_BY_ID_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GetLoanById = createAction(GET_LOAN_BY_ID, props<{ loanId: string }>());
export const GetLoanByIdSuccess = createAction(GET_LOAN_BY_ID_SUCCESS, props<{ loanData: InitiateLoanDetails }>());
export const GetLoanByIdFailure = createAction(GET_LOAN_BY_ID_FAILURE, props<{ errors?: string[]; message: string }>());

export const RESET_LOANS = "RESET_LOANS";
export const ResetLoans = createAction(RESET_LOANS);

export const RESET_LOANS_INFO = "RESET_LOANS_INFO";
export const ResetLoansINFO = createAction(RESET_LOANS_INFO);

export const GET_LOAN_FILTERS = "GET_LOAN_FILTERS";
export const GET_LOAN_FILTERS_SUCCESS = "GET_LOAN_FILTERS_SUCCESS";
export const GET_LOAN_FILTERS_FAILURE = "GET_LOAN_FILTERS_FAILURE";

export const GetLoanFilters = createAction(GET_LOAN_FILTERS, props<{ data: any }>());
export const GetLoanFiltersSuccess = createAction(GET_LOAN_FILTERS_SUCCESS, props<{ data: LoanFilterData[] }>());
export const GetLoanFiltersFailure = createAction(
  GET_LOAN_FILTERS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GET_LOAN_ACCOUNT_AND_ASSIGNED_TO = "GET_LOAN_ACCOUNT_AND_ASSIGNED_TO";
export const GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_SUCCESS = "GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_SUCCESS";
export const GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_FAILURE = "GET_GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_FAILURE";

export const GetLoanAccountAndAssignedTo = createAction(GET_LOAN_ACCOUNT_AND_ASSIGNED_TO, props<{ userId: string }>());
export const GetLoanAccountAndAssignedToSuccess = createAction(
  GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_SUCCESS,
  props<{ data: GetLoanCountAndAssignedToResult }>()
);
export const GetLoanAccountAndAssignedToFailure = createAction(
  GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_FAILURE,
  props<{ userId: string; errors?: string[]; message: string }>()
);

export const ASSIGN_NEW_LOAN = "ASSIGN_NEW_LOAN";
export const ASSIGN_NEW_LOAN_SUCCESS = "ASSIGN_NEW_LOAN_SUCCESS";
export const ASSIGN_NEW_LOAN_FAILURE = "ASSIGN_NEW_LOAN_FAILURE";

export const AssignNewLoan = createAction(ASSIGN_NEW_LOAN, props<{ userId: string }>());
export const AssignNewLoanSuccess = createAction(
  ASSIGN_NEW_LOAN_SUCCESS,
  props<{ data: GetLoanCountAndAssignedToResult }>()
);
export const AssignNewLoanFailure = createAction(
  ASSIGN_NEW_LOAN_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const UPDATE_LOAN_STATUS = "UPDATE_LOAN_STATUS";

export const GET_LOAN_UPDATE_STATUS = "GET_LOAN_UPDATE_STATUS";
export const GET_LOAN_UPDATE_STATUS_SUCCESS = "GET_LOAN_UPDATE_STATUS_SUCCESS";
export const GET_LOAN_UPDATE_STATUS_FAILURE = "GET_LOAN_UPDATE_STATUS_FAILURE";

export const GetLoansUpdateStatus = createAction(GET_LOAN_UPDATE_STATUS, props<{ data: LoanStatusUpdate }>());

export const GetLoansUpdateStatusSuccess = createAction(
  GET_LOAN_UPDATE_STATUS_SUCCESS,
  props<{ data: LoanStatusUpdate }>()
);

export const GetLoansUpdateStatusFailure = createAction(
  GET_LOAN_UPDATE_STATUS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export type LoanActions =
  | ActionType<typeof GetLoanFilters>
  | ActionType<typeof GetLoanFiltersSuccess>
  | ActionType<typeof GetLoanFiltersFailure>
  | ActionType<typeof GetLoans>
  | ActionType<typeof GetLoansSuccess>
  | ActionType<typeof GetLoansFailure>
  | ActionType<typeof InitiateLoan>
  | ActionType<typeof InitiateLoanSuccess>
  | ActionType<typeof InitiateLoanFailure>
  | ActionType<typeof DeleteBankAccountById>
  | ActionType<typeof DeleteBankAccountByIdSuccess>
  | ActionType<typeof DeleteBankAccountByIdFailure>
  | ActionType<typeof ClearInitiateLoanFailure>
  | ActionType<typeof GetLoanById>
  | ActionType<typeof GetLoanByIdSuccess>
  | ActionType<typeof GetLoanByIdFailure>
  | ActionType<typeof ResetLoans>
  | ActionType<typeof ResetLoansINFO>

  //pavan Changes
  | ActionType<typeof GetLoanAccountAndAssignedTo>
  | ActionType<typeof GetLoanAccountAndAssignedToSuccess>
  | ActionType<typeof GetLoanAccountAndAssignedToFailure>
  | ActionType<typeof GetLoansUpdateStatus>
  | ActionType<typeof GetLoansUpdateStatusSuccess>
  | ActionType<typeof GetLoansUpdateStatusFailure>
  | ActionType<typeof AssignNewLoan>
  | ActionType<typeof AssignNewLoanSuccess>
  | ActionType<typeof AssignNewLoanFailure>;
