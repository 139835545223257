import { Component, Inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { TokenStorageService } from "src/app/service/TokenStorageService/token-storage.service";

export interface PageActionButton {
    name: string;
    type: "primary" | "secondary";
    onClick: () => void;
    isDisabled: boolean;
}

@Component({
    selector: "app-page-header",
    templateUrl: "./page-header.component.html",
    styleUrls: ["./page-header.component.css"],
})
export class PageHeaderComponent implements OnInit {
    @Input() actionButtons: PageActionButton[] = [];
    @Input() pageTitle = "";
    isLogOut: boolean = false;
    location = "";
    isProfilePopupVisible = false;

    constructor(
        private msalService: MsalService,
        private router: Router,
        private tokenStorageService: TokenStorageService,
        @Inject(MSAL_GUARD_CONFIG) private msalConfig: MsalGuardConfiguration
    ) {}

    ngOnInit(): void {
        this.location = this.getLocationBasedOnUrl(window.location.href);
    }

    toggleProfilePopup() {
        this.isProfilePopupVisible = !this.isProfilePopupVisible;
    }

    logout() {
        this.tokenStorageService.clearStorage();
        this.msalService.logoutPopup().subscribe(() => {
            window.location.reload();
                    });
    }

    private getLocationBasedOnUrl(currentUrl: string): string {
        if (currentUrl.includes("indemo")) {
            return "https://bsic.indemo.msuite.ai/";
        } else if (currentUrl.includes("usdemo")) {
            return "https://bsic.usdemo.msuite.ai/";
        }
        return "";
    }

    handleOnClick(action: PageActionButton) {
        if (!action.isDisabled) {
            action.onClick();
        }
    }

    trackByFn(index: number, item: PageActionButton): number {
        return index;
    }
}
