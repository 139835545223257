import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";

const AuthLoginData_Storage_Key = "AuthLoginData";

@Injectable({
    providedIn: "root",
})
export class TokenStorageService {
    clearStorage() {
        window.sessionStorage.clear();
        window.localStorage.clear();
    }

    setAuthLoginData(data: AccountInfo) {
        localStorage.setItem(AuthLoginData_Storage_Key, JSON.stringify(data));
    }

    getAuthLoginData(): AccountInfo {
        const data = localStorage.getItem(AuthLoginData_Storage_Key);
        return data ? JSON.parse(data) : null;
    }

    getAccessToken() {
        const data = localStorage.getItem(AuthLoginData_Storage_Key);
        return data ? JSON.parse(data).idToken : null;
    }

    getUserRole() {
        const data = localStorage.getItem(AuthLoginData_Storage_Key);
        return data ? (this.getAuthLoginData().localAccountId == "9836f009-6063-45d1-8dae-70f15ecec1ee" ? 1 : 2) : null;
    }
}
