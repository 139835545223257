import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";

export enum ApplicationRoute {
  Login = "login",
  Demo = "app-demo-page",
  IncomeCalculation = "income-calculation",
  Summary = "summary",
  Loans = "",
  Extraction = "extraction",
  TransactionReview = "transaction-review",
  RulesListComponent = "rules-list",
  CreateRuleComponent = "create-rule",
  RuleConfigurationComponent = "rule-configuration",
  InitateLoan = "initiate-loan",
  EditLoan = "edit-loan",
}

export function RouteTo(appRoute: ApplicationRoute) {
  return `/${appRoute}`;
}

const routes: Routes = [
  {
    path: "",
    canActivate: [MsalGuard],
    loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
    title: "Loans",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
