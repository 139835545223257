import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, catchError, Observable, of, switchMap, tap } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import { BuisnessType, LoanHeaderDetails, UpdateLoanHeaderDetails } from "src/app/models/loans";
import { BankAccount, FileListGrid, StatementSummary } from "src/app/models/statement-summary";
import { GetDocumentByStatementId, GetDocumentByStatementIdFailure } from "src/app/store/summary/summary.actions";
import { selectDocumentByStatementIdSelector } from "src/app/store/summary/summary.selectors";
import { StatementSummaryState } from "src/app/store/summary/summary.types";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class BankStatementExtractionService {
  private isLockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  idToken: string;
  isLocked$: Observable<boolean> = this.isLockedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private store: Store<StatementSummaryState>,
    private tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  getAllLoans(): Observable<ApiResponseModel<LoanHeaderDetails[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<LoanHeaderDetails[]>>(`${this.baseUrl}api/BankStatementExtraction/Loan`, {
      headers,
    });
  }

  getBuisnessTypes(): Observable<ApiResponseModel<BuisnessType[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<BuisnessType[]>>(`${this.baseUrl}api/Common/BusinessType`, { headers });
  }

  getLoanHeaderDetailsById(loanId: string): Observable<ApiResponseModel<LoanHeaderDetails>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<LoanHeaderDetails>>(
      `${this.baseUrl}api/BankStatementExtraction/Loan/${loanId}`,
      { headers }
    );
  }

  updateLoanDetailsById(
    loanHeaderDetails: UpdateLoanHeaderDetails
  ): Observable<ApiResponseModel<UpdateLoanHeaderDetails>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.put<ApiResponseModel<UpdateLoanHeaderDetails>>(
      `${this.baseUrl}api/BankStatementExtraction/Loan`,
      loanHeaderDetails,
      { headers }
    );
  }

  getBankAccountsByLoanId(loanId: string): Observable<ApiResponseModel<BankAccount[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<BankAccount[]>>(
      `${this.baseUrl}api/BankStatementExtraction/Loan/BankAccounts/${loanId}`,
      { headers }
    );
  }

  getStatementSummaryByBankAccId(bankAccountId: string): Observable<ApiResponseModel<StatementSummary[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<StatementSummary[]>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/${bankAccountId}`,
      { headers }
    );
  }

  setIsLocked(value: boolean): void {
    this.isLockedSubject.next(value);
  }

  updateStatementSummary(statementSummaries: StatementSummary[]): Observable<ApiResponseModel<any>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<any>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary`,
      statementSummaries,
      { headers }
    );
  }

  updateBankAccount(statementId: string, bankAccountId: string): Observable<ApiResponseModel<any>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.put<ApiResponseModel<any>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/BankAccount`,
      { statementId, bankAccountId },
      { headers }
    );
  }

  lockBankStatementSummary(loanId: string): Observable<ApiResponseModel<null>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.put<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/LockBankStatementSummary/${loanId}`,
      {},
      { headers }
    );
  }

  unlockBankStatementSummary(loanId: string): Observable<ApiResponseModel<null>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.put<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/UnlockBankStatementSummary/${loanId}`,
      {},
      { headers }
    );
  }

  bankStatementFileUpload(
    selectedFiles: string[],
    bankAccountId: string,
    statementSummaryId: string
  ): Observable<ApiResponseModel<null>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/Files/${bankAccountId}/${statementSummaryId}`,
      selectedFiles,
      { headers }
    );
  }

  getStatementSummaryFile(statementSummaryId: string): Observable<ApiResponseModel<any>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<string>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/File/${statementSummaryId}`,
      { headers }
    );
  }

  getFilesByLoanId(loanId: string): Observable<ApiResponseModel<FileListGrid[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<FileListGrid[]>>(
      `${this.baseUrl}api/BankStatementExtraction/Loan/Files/${loanId}`,
      { headers }
    );
  }

  uploadStatementFile(loanId: string, files: File[]): Observable<ApiResponseModel<string>> {
    const headers = new HttpHeaders({
      accept: "*/*",
      Authorization: `Bearer ${this.idToken}`,
    });
    const formData = new FormData();
    files.forEach((f) => formData.append("file", f, f.name));
    return this.http.post<ApiResponseModel<string>>(
      `${this.baseUrl}api/BankStatementExtraction/Loan/Files/${loanId}`,
      formData,
      { headers }
    );
  }

  processLoanTransactionFlagsFor(loanId: string): Observable<ApiResponseModel<null>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/ProcessTransactionFlags/${loanId}`,
      {},
      { headers }
    );
  }

  processLoanCategory(loanId: string): Observable<ApiResponseModel<null>> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<null>>(
      `${this.baseUrl}api/BankStatementExtraction/StatementSummary/ProcessCategory/${loanId}`,
      {},
      { headers }
    );
  }

  documentByStatementId(statementId: string, onCallBack: (document: string | undefined) => void): void {
    this.store
      .pipe(
        select(selectDocumentByStatementIdSelector(statementId)),
        tap((currentDocument) => {
          if (currentDocument) {
            onCallBack(currentDocument);
          }
        }),
        switchMap((currentDocument) => {
          if (currentDocument) {
            return of(currentDocument);
          } else {
            return this.getStatementSummaryFile(statementId).pipe(
              tap((response: ApiResponseModel<string>) => {
                const document = response.data ? response.data : undefined;
                if (document) {
                  this.store.dispatch(GetDocumentByStatementId({ statementId }));
                  onCallBack(document);
                }
              }),
              catchError((error) => {
                this.store.dispatch(GetDocumentByStatementIdFailure({ statementId, message: error.message }));
                onCallBack(undefined);
                return of(undefined);
              })
            );
          }
        })
      )
      .subscribe();
  }

  fetchDocument(statementId: string, freshFetch = false): Observable<any> {
    return this.store.pipe(
      select(selectDocumentByStatementIdSelector(statementId)),
      switchMap((document) => {
        if (document && !freshFetch) {
          // Document exists in store and no fresh fetch is requested
          return of(document);
        } else {
          // Document does not exist or fresh fetch is requested
          this.store.dispatch(GetDocumentByStatementId({ statementId, freshFetch }));
          // Optionally, you can return an observable that waits for the fetch result
          return this.store.pipe(
            select(selectDocumentByStatementIdSelector(statementId)),
            // Consider adding a timeout or retry strategy for robustness
            tap((result) => {
              if (!result) {
                throw new Error("Document not available yet.");
              }
            })
          );
        }
      }),
      catchError((error) => {
        console.error("Error fetching document:", error);
        return of(null); // or handle the error appropriately
      })
    );
  }

  autoValidateTransactions(loanNo: string) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json" });
    return this.http.post<ApiResponseModel<string>>(
      `${this.baseUrl}api/StatementSummary/Validate/${loanNo}`,
      {},
      { headers }
    );
  }
}
