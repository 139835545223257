<div>
  <div class="pdf-header d-flex space-evenly">
    <button class="d-none btn btn-sm d-flex align-items-center btn-outline-primary">
      <span>Switch to New Tab</span>
    </button>
    <label style="margin-left: auto">{{ pdfSource?.name }}</label>
  </div>
  <div class="slider-container">
    <ngx-slider [(value)]="zoomLevel" [options]="zoomSlider" (userChange)="updateZoomLevel($event)" />
  </div>
  <pdf-viewer
    *ngIf="base64DocumentData"
    #pdfviewer
    [src]="base64DocumentData"
    [render-text]="true"
    [original-size]="true"
    [show-all]="true"
    [fit-to-page]="true"
    [zoom]="zoomLevel"
    [ngStyle]="{ height: calculatePDFHeight }"
    style="display: block; min-height: 120px"
    (page-rendered)="pageRendered($event)" />
</div>
